var box_typ_01 = function () {
    $('.box_typ_01 .headline').matchHeight();
    $('.box_typ_01 .content').matchHeight();

    // $('#einleitung .no-gutters>div').matchHeight();

    //    $('.box_typ_01 .content p:last-of-type()').width(($('.box_typ_01 .inner').width() - (parseInt($('.box_typ_01 .content').css('padding-left')) * 2)));
    //    
    //    $('.box_typ_01 .content').css('padding-bottom', $('.box_typ_01 .inner p:last-of-type()').outerHeight() + (parseInt($('.box_typ_01 .content').css('padding-left'))));
    //
    $('.box_typ_01').css('opacity', 1);
};




$(document).ready(function () {
    box_typ_01();

    // Thanks to Maaaaark - https://github.com/maaaaark/bcSwipe/blob/master/jquery.bcSwipe.min.js
    ! function (t) {
        t.fn.bcSwipe = function (e) {
            var n = {
                threshold: 50
            };
            return e && t.extend(n, e), this.each(function () {
                function e(t) {
                    1 == t.touches.length && (u = t.touches[0].pageX, c = !0, this.addEventListener("touchmove", o, !1))
                }

                function o(e) {
                    if (c) {
                        var o = e.touches[0].pageX,
                            i = u - o;
                        Math.abs(i) >= n.threshold && (h(), t(this).carousel(i > 0 ? "next" : "prev"))
                    }
                }

                function h() {
                    this.removeEventListener("touchmove", o), u = null, c = !1
                }
                var u, c = !1;
                "ontouchstart" in document.documentElement && this.addEventListener("touchstart", e, !1)
            }), this
        }
    }(jQuery);

    // Swipe functions for Bootstrap Carousel
    $('.carousel').bcSwipe({
        threshold: 50
    });
});

$(window).resize(function () {});